<template>
  <div class="code d-flex align-items-center">
    <div class="codes">
      <Transition name="show">
        <div v-if="recoveryCodes.length">
          <AppText
            v-for="item in recoveryCodes"
            :key="item"
            variant="p"
            class="font-medium"
            size="20px"
          >
            {{ item }}
          </AppText>
        </div>
      </Transition>
    </div>

    <FButton class="download-button" @click="onSaveCodes">
      <AppIcon name="download-colored" size="26px" is-img-tag />
      Save
    </FButton>
  </div>
  <div class="checkboxes">
    <FCheckbox
      v-for="(item, key) in termsList"
      :key="key"

      v-model="item.value"
      :autofocus="key === 0"
      :label="item.text"
      is-wrap
      @keypress.enter="onSubmit"
    />
  </div>
  <FButton
    is-full-width
    no-margin
    :disabled="!isTermsAccepted"
    @click="onSubmit"
  >
    Finish
  </FButton>
</template>

<script>
import { computed, defineComponent, reactive } from 'vue';

import { downloadAsFile } from '@/utils/functions';

export default defineComponent({
  props: {
    recoveryCodes: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['submit'],
  setup(props, { emit }) {
    const termsList = reactive([
      {
        value: false,
        text: 'Account recovery is not possible without either a valid TOTP or a recovery code.',
      },
      {
        value: false,
        text: 'I have safely stored the codes.',
      },
    ]);

    const isTermsAccepted = computed(() => termsList.every((el) => el.value));

    const onSubmit = async () => {
      if (isTermsAccepted.value) {
        emit('submit');
      }
    };

    const onSaveCodes = () => {
      if (props.recoveryCodes.length) {
        downloadAsFile({
          data: props.recoveryCodes,
          filename: 'recovery-codes',
        });
      }
    };

    return {
      termsList,
      isTermsAccepted,

      onSubmit,
      onSaveCodes,
    };
  },
});
</script>

<style scoped lang="scss">
.code {
  width: 100%;
  border-radius: 8px;
  padding-bottom: 30px;
  min-height: 200px;

  :deep(.text){
    margin-bottom: 16px;
  }

  :deep(.text:last-child){
    margin-bottom: 0;
  }
}

.codes {
  border: 1px solid var(--color-black-01);
  border-radius: 8px;
  padding: 20px 35px;
  margin-right: 60px;

  display: flex;
}

.checkboxes {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

:deep(.el-button.download-button) {
  background-color: #fff;
  width: 80px;
  height: 80px;
  padding-left: initial !important;
  padding: 30px !important;
  .icon {
    margin-right: 0 !important;
    margin-bottom: 12px;
  }
  > span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--color-black);
  }
  &:hover {
    background-color: #F2F2F2;
  }
}
</style>
