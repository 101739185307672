<template>
  <div
    :class="{ inline: type === 'inline', block: type === 'block' }"
  >
    <AppIcon name="uid-circle" size="48px" />
    <AppText
      class="description"
      size="12px"
      opacity="0.4"
    >
      {{ t('merchantSettings.general.accountNumber') }} / UID
    </AppText>
    <TooltipCopy
      is-icon-hidden
    >
      <AppText
        size="17px"
        class="uid font-medium"
        spacing="0.2em"
      >
        {{ uid }}
      </AppText>
    </TooltipCopy>
  </div>
</template>

<script>
import TooltipCopy from '@/components/TooltipCopy.vue';

export default {
  name: 'UidBadge',
  components: {
    TooltipCopy,
  },
  props: {
    uid: {
      type: [Number, String],
      default: '',
    },
    type: {
      type: String,
      default: 'inline',
      validate(value) {
        return ['inline', 'block'].includes(value);
      },
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.inline {
  display: flex;
  background: #ebebeb;
  border-radius: 48px;

  .description {
    display: none;
  }

  .uid {
    display: flex;
    align-items: center;
    margin: 0 20px 0 17px;
  }
}

.block {
  margin-top: 40px;
  background: #F5F5F5;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 60px 40px;
  align-items: center;

  .description {
    margin: 24px 0 15px;
  }

  .uid {
    font-size: 18px;
  }
}
</style>
